import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/promotions/layout"
import SEO from "../../components/common/seo"

import PaymentLink from "../../components/promotions/paymentLink"

// import { toQueryString } from "../../utils/urls"
import { sponsorPromotionLink } from "../../components/promotions/constants"


const SponsorPromotionPage = ({data, path}) => {

  return (
    <Layout pageTitle="Advertise on the Hive Index" enableShare={true} path={path} includeBlurb={true} >
      <SEO title="Site Sponsorship | The Hive Index" description="Sponsor the Hive Index directory to spread awareness of your community, community platform, or tool."
         generateImage={true} path={'/partners/sponsor/'}
      />

      <div className="mx-auto mt-6 rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
        <div className="p-6 sm:p-8 lg:flex-auto">
          <h3 className="text-2xl font-bold tracking-tight text-gray-900">Site Sponsorship (1 month)</h3>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Promote your brand to 45k+ community seekers, owners, and professionals
          </p>
          <p className="mt-2 text-base leading-7 text-gray-600">
            Questions on the sponsorship/traffic? Please <a href="mailto:contact@thehiveindex.com?subject=Sponsorship" className="font-semibold underline text-rose-600">send an email</a>.
          </p>
          <div className="mt-4 flex items-center gap-x-4">
            <h4 className="flex-none text-sm font-semibold leading-6 text-rose-600">Sponsorship includes</h4>
            <div className="h-px flex-auto bg-gray-100" />
          </div>
          <ul
            role="list"
            className="mt-4 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
          >
            <li className="flex gap-x-3">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                className="h-6 w-5 flex-none text-rose-600"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              "Brought to you by" link in the banner on every page (200k+ pageviews/mo)
            </li>
            <li className="flex gap-x-3">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                className="h-6 w-5 flex-none text-rose-600"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              Shoutout on homepage and site footer
            </li>
            <li className="flex gap-x-3">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                className="h-6 w-5 flex-none text-rose-600"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <div>Your ad on the community exit screen</div>
            </li>
            {/*<li className="flex gap-x-3">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                className="h-6 w-5 flex-none text-rose-600"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <div>Included in community email digest</div>
            </li>*/}
            {/*<li className="flex gap-x-3">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                className="h-6 w-5 flex-none text-rose-600"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
              <div>Shoutout on Twitter from @foliofed</div>
            </li>*/}
          </ul>
        </div>

        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
          <div className="rounded-2xl bg-yellow-50 py-12 text-center ring-1 ring-inset ring-yellow-100 lg:flex lg:flex-col lg:justify-center lg:py-12">
            <div className="mx-auto max-w-xs px-1">
              <p className="text-base font-semibold text-gray-600">1-month sitewide promotion</p>
              <p className="mt-6 flex items-baseline justify-center gap-x-2">
                {/*<span className="text-3xl opacity-30 line-through font-bold tracking-tight text-gray-900">$1000</span>*/}
                <span className="text-5xl font-bold tracking-tight text-gray-900">$1000</span>
                {/*<span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>*/}
              </p>
              <PaymentLink
                url={sponsorPromotionLink}
                params={{'utm_content': 'sponsor_promotion'}}
                title="Sponsor the site"
                additionalClassName="mt-10"
              />
              {/*<p className="mt-6 text-xs leading-5 text-gray-600">
               Free communities can be listed on up to 3 topic pages
              </p>*/}
            </div>
          </div>
        </div>
      </div>

      {/*<div className="relative mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Availability</h2>
        </div>
      </div>

      <div className="mt-2 text-gray-500">
        <table>
          <thead>
            <th>
              <td>Month</td>
              <td>Available</td>
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Mar 2024</td>
              <td>❌</td>
            </tr>
            <tr>
              <td>Apr 2024</td>
              <td>❌</td>
            </tr>
            <tr>
              <td>May 2024</td>
              <td>❌</td>
            </tr>
            <tr>
              <td>Jun 2024</td>
              <td>✅</td>
            </tr>
            <tr>
              <td>Jul 2024</td>
              <td>✅</td>
            </tr>
            <tr>
              <td>Aug 2024</td>
              <td>✅</td>
            </tr>
          </tbody>
        </table>
      </div>*/}

      <div className="relative mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">FAQ</h2>
        </div>
      </div>

      <div className="mt-2 text-gray-500">
        <p className="mb-2">
          <b>What can I promote with my sponsorship?</b><br/>
          You can promote any business that lines up with the Hive Index values. However, the best results will come from promoting a community platform, community tool, or other community-centered business.
        </p>
        {/*<p className="mb-2">
          <b>How much traffic will I get?</b><br/>
          Average of past 3 months is 1700 clicks, highest being 2200.
        </p>*/}
        <p className="mb-2">
          <b>Other questions?</b><br/>
          <a href="mailto:contact@thehiveindex.com" className="font-semibold underline text-rose-600">Send an email</a> and get in touch!
        </p>
        {/*<p className="mb-2">
          <b>I'm not ready to pay, can I list my community for free?</b><br/>
          Yes, it's free to <Link to="/submit/" className="font-semibold underline">submit your community</Link> on the Hive Index.<br/>
          Your community will show up on relevant topic/feature pages in the default order & display style.
        </p>*/}
      </div>

    </Layout>
  )
}


export default SponsorPromotionPage
